var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CChartPie',{attrs:{"datasets":_vm.defaultDatasets,"labels":[
    'Project',
    'Tâches',
    'Memos',
    'Bugs',
    'Corrigés',
    'test',
    'À-faire' ]}})}
var staticRenderFns = []

export { render, staticRenderFns }